import { Action, createReducer, on } from '@ngrx/store';
import * as activeHostsActions from '../actions/active-hosts.action';
import { ActiveHostsReducer } from '../models/active-hosts.model';

const initialState: ActiveHostsReducer = {
  data: [],
  state: {},
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 5,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  config: {
    enableMonitoring: false,
    interfaceList: [],
  },
  networkInterfaces: [],
  columns: {
    interface: '',
    ip_address: '',
    mac_address: '',
    device_name: '',
    connected_time: '',
  },
  globalSearch: '',
};

const reducer = createReducer(
  initialState,

  on(activeHostsActions.setNetworkInterfaces, (state, props) => ({
    ...state,
    networkInterfaces: props.networkInterfaces,
  })),

  on(activeHostsActions.setActiveHostsConfig, (state, props) => ({
    ...state,
    config: props.activeHostsConfig,
  })),

  on(activeHostsActions.setActiveHostsDetails, (state, props) => ({
    ...state,
    data: props.activeHostsDetails.data,
    state: props.activeHostsDetails.state,
    pagination: props.activeHostsDetails.pagination,
  })),

  on(activeHostsActions.setActiveHostsColumnSearch, (state, props) => {
    const key = Object.keys(props.activeHostsColumnSearch)[0];
    return {
      ...state,
      columns: {
        ...state.columns,
        [key]: Object.values(props.activeHostsColumnSearch)[0],
      },
    };
  }),

  on(activeHostsActions.setActiveHostsGlobalSearch, (state, props) => ({
    ...state,
    globalSearch: props.globalSearch,
  })),

  on(activeHostsActions.setActiveHostsCurrentPage, (state, props) => ({
    ...state,
    pagination: {
      ...state.pagination,
      current_page: props.currentPage,
    },
  }))
);

export function activeHostsReducer(state: ActiveHostsReducer | undefined, action: Action) {
  return reducer(state, action);
}
